var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfSection',{staticClass:"products",class:_vm.className},[_c('div',{staticClass:"panel",class:_vm.adsPosition},[_c('div',{staticClass:"panel__left"},[_c('div',{staticClass:"heading"},[_c('h4',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),(_vm.link)?_c('SfLink',{attrs:{"link":_vm.link}},[_vm._v("\n          "+_vm._s(_vm.buttonText)+"\n          "),_c('SfIcon',{attrs:{"size":"1.25rem","icon":"arrow_right"}})],1):_vm._e()],1),_vm._v(" "),_c('Carousel',{directives:[{name:"show",rawName:"v-show",value:(_vm.products.length),expression:"products.length"}],staticClass:"product-carousel",attrs:{"data-cy":"category-products-carousel","settings":{
          type: 'carousel',
          perView: 6,
          perTouch: 2,
          gap: 7,
          breakpoints: {
            1680: {
              perView: 6,
              perTouch: 2,
            },
            1480: {
              perView: 5,
            },
            1280: {
              perView: 4,
            },
            991: {
              perView: 3,
            },
            580: {
              rewind: false,
              perView: 2.4,
              peek: {
                before: 0,
                after: 0,
              },
            },
          },
        }}},_vm._l((_vm.products),function(product,i){return _c('CarouselItem',{key:i,staticClass:"carousel__item"},[_c('ProductContainer',{attrs:{"product":product}})],1)}),1),_vm._v(" "),(!_vm.products.length)?_c('SfLoader'):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"panel__right"},[_c('div',{staticClass:"ads-slot"},[_c('CmsBlocks',{attrs:{"link":_vm.adsLink,"className":_vm.adsClassName,"identifier":_vm.adsIdentifier}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }