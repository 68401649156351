









































































import {
  SfLink,
  SfLoader,
  SfSection,
  SfImage,
  SfButton,
  SfIcon,
} from "@storefront-ui/vue";
import SvgImage from "~/components/General/SvgImage.vue";
import {
  defineComponent,
  ref,
  onMounted,
} from "@nuxtjs/composition-api";
export default defineComponent({
  name: "ProductPanel",
  components: {
    SfSection,
    SfLoader,
    SfLink,
    SfImage,
    SfButton,
    SvgImage,
    SfIcon,
    CmsBlocks: () =>
      import(/* webpackPrefetch: true */ "~/components/CmsBlocks.vue"),
    CategoryEmptyResults: () =>
      import("~/modules/catalog/category/components/CategoryEmptyResults.vue"),
    ProductContainer: () =>
      import("~/components/Custom/Organisms/ProductContainer.vue"),
    Carousel: () => import("~/components/Custom/Organisms/Carousel.vue"),
  },
  props: {
    products: {
      type: [],
      default: null,
    },
    className: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    adsPosition: {
      type: String,
      default: "",
    },
    adsClassName: {
      type: String,
      default: "",
    },
    adsLink: {
      type: String,
      default: "",
    },
    adsIdentifier: {
      type: String,
      default: "",
    },
  },
  setup() {
    const isLoading = ref(true);

    onMounted(() => {
      isLoading.value = false;
    });
  },
});
